import { BUSINESS_INFO_NOT_FOUND } from '~/assets/strings';

export class CannotScreeningError extends Error {
  constructor() {
    super(BUSINESS_INFO_NOT_FOUND);
    Object.setPrototypeOf(this, CannotScreeningError.prototype);
  }
}

export const COMPANY_BUSINESS_INFO_NOT_FOUND_EXCEPTION = 'CompanyBusinessInfoNotFoundException';
