import { Presenter } from '~/framework/Presenter';
import { currency } from '~/global-contexts/utils/number';
import { RentalApplicationErrors, RentalApplicationInfo, StepError, User } from '~/state/mainAppState';
import { Address } from '~/types/Address';
import { PresentablePaymentInfo } from '~/types/PresentablePaymentInfo';
import { PresentableUnit } from '~/types/PresentableUnit';
import {
  FileType,
  TransUnionScreeningStatus,
  TransUnionScreening,
  QuestionAndAnswers,
  Question,
  QuestionType,
  CommentSection,
  RentalApplicationComment,
} from '~/types/RentalApplication';
import { RentalApplicationSteps } from '~/types/RentalApplicationSteps';
import { CompanyUnit, PaginatedUnits } from '~/types/Unit';
import { statesAndProvincesMap } from '~/use-cases/rental-applications/countries';
import { isImageUrl } from '~/utils/fileType';

export interface SelectionValue {
  value: string;
  label: string;
  isSelected: boolean;
}

type UnitsOfInterest = PaginatedUnits<PresentableUnit> & {
  ended?: boolean;
  hasUnits?: boolean;
};

export interface PresentableRentalApplication {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  dateOfBirth?: string;
  ssn?: string;
  desiredMoveInDate?: string;
  governmentIdFiles?: PresentableFile[];
  bankStatementFiles?: PresentableFile[];
  annualIncome?: string;
  url?: string;
  rentalHistory?: PresentableRentalHistory[];
  employmentHistory?: PresentableEmploymentInformation[];
  errors: RentalApplicationErrors;
  hasRentalApplicationInfoError?: boolean;
  hasRentalHistoryError?: boolean;
  hasEmploymentHistoryError?: boolean;
  hasTermsAndConditionsError?: boolean;
  hasUnionQuestionnaireError?: boolean;
  unitsOfInterest?: UnitsOfInterest;
  preSelectedUnits?: CompanyUnit[];
  isOpenToAllUnits?: boolean;
  unitsListingSearch?: string;
  hasAcceptedMagicDoorTerms?: boolean;
  hasAcceptedTransUnionTerms?: boolean;
  paymentInfo?: PresentablePaymentInfo;
  isPaid?: boolean;
  isLocked?: boolean;
  transUnionScreening?: TransUnionScreening;
  questionsAndAnswers?: PresentableQuestionsAndAnswers[];
  comments?: RentalApplicationComment[];
}

export interface PresentableTransUnionScreening {
  questions: QuestionAndAnswers[];
  screeningStatus: TransUnionScreeningStatus;
}

export interface PresentableEmploymentInformation {
  name: string;
  phone: string;
  position: string;
  salary?: string;
  startDate?: string;
  endDate?: string;
}

export interface PresentableRentalHistory {
  address?: Address;
  landlordName?: string;
  landlordPhone?: string;
  rent?: string;
  moveInDate?: string;
  moveOutDate?: string;
  reasonForLeaving?: string;
  countries: SelectionValue[];
  states: SelectionValue[];
  selectedCounty?: string;
  selectedState?: string;
}

export interface LabelValuePair {
  label: string;
  value: string;
}

export interface PresentableQuestionsAndAnswers {
  question: string;
  options: LabelValuePair[];
  answers: string[];
  type: QuestionType;
}

export interface PresentableFile {
  id: string;
  path: string;
  name: string;
  type: string;
  isImage: boolean;
}

export class RentalApplicationPresenter extends Presenter<PresentableRentalApplication> {
  protected createModel = (state: User): PresentableRentalApplication | undefined => {
    const rentalApplication = state.rentalApplication.application;

    return {
      firstName: rentalApplication?.firstName,
      lastName: rentalApplication?.lastName,
      email: rentalApplication?.email,
      phoneNumber: rentalApplication?.phone,
      dateOfBirth: rentalApplication?.dateOfBirth,
      ssn: rentalApplication?.ssn,
      desiredMoveInDate: rentalApplication?.desiredMoveInDate,
      governmentIdFiles: this.getFilesOfType(state, FileType.IDENTIFICATION),
      bankStatementFiles: this.getFilesOfType(state, FileType.BANK_STATEMENT),
      unitsOfInterest: this.getUnitsOfInterest(state),
      preSelectedUnits: state.unitsListing.preSelectUnits,
      isOpenToAllUnits: !state.rentalApplication.application?.interestedUnits?.length,
      unitsListingSearch: state.unitsListing?.search,
      annualIncome: rentalApplication?.annualIncome ? currency(rentalApplication?.annualIncome) : undefined,
      url: `/rental-applications/${rentalApplication?.credentials.id}/${rentalApplication?.credentials.password}`,
      rentalHistory: this.getRentalHistory(state),
      employmentHistory: (rentalApplication?.employmentHistory || []).map((history) => {
        return {
          name: history.name,
          phone: history.phone,
          position: history.position,
          salary: currency(history.salary),
          startDate: history.startDate,
          endDate: history.endDate,
        };
      }),
      hasAcceptedMagicDoorTerms: rentalApplication?.hasAcceptedMagicDoorTerms || false,
      hasAcceptedTransUnionTerms: rentalApplication?.hasAcceptedTransUnionTerms || false,
      errors: state.rentalApplication.errors,
      hasRentalApplicationInfoError: Object.values(
        state.rentalApplication.errors?.[RentalApplicationSteps.APPLICANT_INFORMATION] ?? {}
      ).some((errorCode) => errorCode !== undefined),
      hasRentalHistoryError: ((state.rentalApplication.errors?.[RentalApplicationSteps.RENTAL_HISTORY] ?? []) as StepError[]).some((item) =>
        Object.values(item).some((errorCode) => errorCode !== undefined)
      ),
      hasEmploymentHistoryError:
        (state.rentalApplication.errors?.singleFiled as StepError)?.annualIncome !== undefined ||
        ((state.rentalApplication.errors?.[RentalApplicationSteps.WORK_HISTORY] ?? []) as StepError[]).some((errorCodes) =>
          Object.values(errorCodes).some((errorCode) => errorCode !== undefined)
        ),
      hasTermsAndConditionsError:
        ((state.rentalApplication.errors?.[RentalApplicationSteps.TERMS_AND_CONDITIONS] ?? {}) as StepError).magicDoorLicense !==
          undefined ||
        ((state.rentalApplication.errors?.[RentalApplicationSteps.TERMS_AND_CONDITIONS] ?? {}) as StepError).transUnionLicense !==
          undefined,
      hasUnionQuestionnaireError: Object.values(state.rentalApplication.errors?.[RentalApplicationSteps.TRANS_UNION_QUESTIONS] ?? {}).some(
        (errorCode) => errorCode !== undefined
      ),
      paymentInfo: this.createPresentablePaymentInfo(state),
      isPaid: state.rentalApplication.isPaid,
      isLocked: !state.rentalApplication.application?.isDraft,
      transUnionScreening: state.rentalApplication.transUnionScreening,
      questionsAndAnswers: this.createQuestionsAndAnswers(state.rentalApplication),
      comments: state.rentalApplication.application?.comments,
    };
  };

  private createPresentablePaymentInfo = (state: User): PresentablePaymentInfo => {
    return {
      account: state.rentalApplication.stripe?.account,
      clientSecret: state.rentalApplication.stripe?.clientSecret,
      sessionId: state.rentalApplication.stripe?.sessionId,
      stripeAccountId: state.rentalApplication.stripe?.stripeAccountId || '',
      totalAmount: state.rentalApplication.settings?.paymentAmount || 0,
    };
  };

  private createQuestionsAndAnswers = (rentalApplication: RentalApplicationInfo): PresentableQuestionsAndAnswers[] => {
    const result: PresentableQuestionsAndAnswers[] = [];
    rentalApplication.settings?.questions.forEach((question: Question) => {
      const answers = rentalApplication.application?.questionsAndAnswers?.find((qa) => qa.question === question.text)?.answers || [];
      result.push({
        question: question.text,
        type: question.type,
        options: question.options?.map((option) => ({ label: option, value: option })) ?? [],
        answers,
      });
    });
    return result;
  };

  private getRentalHistory = (state: User): PresentableRentalHistory[] => {
    const history = state.rentalApplication.application?.residentialHistory;
    if (!history || history.length === 0) {
      const countries = this.getCountries(history?.[0]?.address?.country);
      const states = this.getStates(history?.[0]?.address?.country, history?.[0]?.address?.state);
      return [
        {
          countries,
          states,
          selectedCounty: countries.find((country) => country.isSelected)?.value,
          selectedState: states.find((state) => state.isSelected)?.value,
        },
      ];
    }
    return history.map((rentalHistory) => {
      const countries = this.getCountries(rentalHistory.address?.country);
      const states = this.getStates(rentalHistory.address?.country, rentalHistory.address?.state);
      return {
        ...rentalHistory,
        rent: rentalHistory.rent ? currency(rentalHistory.rent) : '',
        countries,
        states,
        selectedCounty: countries.find((country) => country.isSelected)?.value,
        selectedState: states.find((state) => state.isSelected)?.value,
      };
    });
  };

  private getCountries(selectedCountry?: string): SelectionValue[] {
    const countries: SelectionValue[] = [];
    for (const country of Object.keys(statesAndProvincesMap)) {
      countries.push({
        value: country,
        label: country,
        isSelected: selectedCountry ? country === selectedCountry : country === 'USA',
      });
    }
    return countries;
  }

  private getStates(selectedCountry?: string, selectedState?: string): SelectionValue[] {
    const states: SelectionValue[] = [];
    const targetCountry = selectedCountry || 'USA';
    const stateData = (statesAndProvincesMap as Record<string, { abbreviations: string[]; fullNames: string[] }>)[targetCountry];

    if (stateData) {
      const stateArr = stateData.abbreviations;
      const stateNames = stateData.fullNames;

      stateArr.forEach((state, index) => {
        states.push({
          value: state,
          label: stateNames[index],
          isSelected: selectedState ? state === selectedState : index === 0,
        });
      });
    }

    return states;
  }

  private getUnitsOfInterest = (state: User): UnitsOfInterest => {
    return {
      pageSize: state.unitsListing?.pageSize,
      currentPage: state.unitsListing?.currentPage,
      totalCount: state.unitsListing?.totalCount,
      totalPages: state.unitsListing?.totalPages,
      ended: Number(state.unitsListing?.currentPage) >= Number(state.unitsListing?.totalPages),
      hasUnits: state.unitsListing?.hasUnits,
      items:
        state.unitsListing?.items?.map((unit) => {
          return {
            id: unit?.id || '',
            name: unit?.title || '',
            bedrooms: unit?.beds?.toLocaleString() || '',
            bathrooms: unit?.baths?.toLocaleString() || '',
            address: unit.singleLineAddress || '',
            isSelected: unit.id ? state.rentalApplication.application?.interestedUnits?.includes(unit.id) : false,
            area: unit?.unitSizeSqft?.toLocaleString() || '',
            isAvailable: !!unit.listed,
            images: unit.primaryImage ? [unit.primaryImage?.signedThumbUrl ?? ''] : [],
          };
        }) || [],
    };
  };

  private getAddressString = (address?: Address): string => {
    if (!address) {
      return '';
    }
    let addressString = address.streetAddress1 || '';
    if (address.streetAddress2 && address.streetAddress2.length > 0) {
      addressString += `, ${address.streetAddress2}`;
    }
    if (address.city && address.city.length > 0) {
      addressString += `, ${address.city}`;
    }
    if (address.state && address.state.length > 0) {
      addressString += `, ${address.state}`;
    }
    return addressString;
  };

  private getFilesOfType = (state: User, type: FileType): PresentableFile[] => {
    const rentalApplication = state.rentalApplication.application;
    let files: PresentableFile[] = [];
    if (rentalApplication?.files) {
      files = rentalApplication.files
        .filter((file) => file.type === type)
        .map((file) => {
          return {
            id: file.fileId,
            name: file.fileName,
            type: file.type,
            path: file.thumbUrl ? file.thumbUrl : file.fileUrl,
            isImage: isImageUrl(file.fileUrl),
          } as PresentableFile;
        });
    }
    if (rentalApplication?.newFiles) {
      files = [
        ...files,
        ...rentalApplication.newFiles
          .filter((file) => file.type === type)
          .map((file) => {
            return {
              id: file.id,
              name: file.name,
              type: file.type,
              path: URL.createObjectURL(file.file),
              isImage: file.isImage,
            };
          }),
      ];
    }
    return files;
  };

  static getComment = (comments: RentalApplicationComment[] = [], field: CommentSection) => {
    return comments?.find((comment) => comment.section === field)?.comment || '';
  };

  static setComment = (comments: RentalApplicationComment[] = [], field: CommentSection, comment: string) => {
    const existIndex = comments.findIndex((comment) => comment.section === field);
    if (existIndex !== -1) {
      comments = comments.toSpliced(existIndex, 1, { section: field, comment });
    } else {
      comments.push({ section: field, comment });
    }
    return comments;
  };
}
