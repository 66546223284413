import { HttpErrorJson, NotFoundError } from '@magicdoor/errors';
import { CannotScreeningError, COMPANY_BUSINESS_INFO_NOT_FOUND_EXCEPTION } from '~/errors/cannotScreeningError';
import { RentalApplicationCredentials } from '~/state/mainAppState';
import {
  RentalApplication,
  FileDescriptor,
  HydratedRentalApplicationFileDto,
  TransUnionScreening,
  TransUnionAnswerSheet,
  TransUnionScreeningStatus,
  RentalApplicationSettings,
  QuestionAndAnswers,
  FileUploadResult,
} from '~/types/RentalApplication';
import { StripeInfo } from '~/types/StripeInfo';
import { Gateway } from './gateway';
import { companyIdAttachingNetworkManager } from './network/companyIdAttachingNetworkManager';
import { MagicRequest, RequestMethod } from './network/magicRequest';

const RENTAL_APPLICATIONS_URL = '/api/rental-applications';

class RentalApplicationGateway extends Gateway {
  async getRentalApplication(credentials: RentalApplicationCredentials): Promise<RentalApplication | undefined> {
    const request: MagicRequest = {
      url: `${RENTAL_APPLICATIONS_URL}/${credentials.id}/${credentials.password}`,
      method: RequestMethod.GET,
      headers: this.createCommonHeaders(),
    };
    try {
      const response = await this.sendRequest(request);
      const json = await response.json();
      return this.createRentalApplicationFromJson(json);
    } catch (error) {
      if (!(error instanceof NotFoundError)) {
        throw error;
      }
    }
    return undefined;
  }

  async getApplicationSettings(): Promise<RentalApplicationSettings> {
    const request: MagicRequest = {
      url: `${RENTAL_APPLICATIONS_URL}/settings`,
      method: RequestMethod.GET,
      headers: this.createCommonHeaders(),
    };
    const response: Response = await this.sendRequest(request);
    const json = await response.json();
    return this.createRentaApplicatonSettingsFromJson(json);
  }

  async createApplication(): Promise<RentalApplication> {
    const request: MagicRequest = {
      url: `${RENTAL_APPLICATIONS_URL}`,
      method: RequestMethod.POST,
      headers: this.createCommonHeaders(),
      body: JSON.stringify({}),
    };
    const response: Response = await this.sendRequest(request);
    const json = await response.json();
    return this.createRentalApplicationFromJson(json);
  }

  async updateApplication(application: RentalApplication): Promise<RentalApplication> {
    const request: MagicRequest = {
      url: `${RENTAL_APPLICATIONS_URL}/${application.credentials.id}/${application.credentials.password}`,
      method: RequestMethod.PUT,
      headers: this.createCommonHeaders(),
      body: this.createRentalApplicationUpdateRequestBody(application),
    };
    const response: Response = await this.sendRequest(request);
    const json = await response.json();
    return this.createRentalApplicationFromJson(json);
  }

  private createRentaApplicatonSettingsFromJson = (json: any): RentalApplicationSettings => {
    return {
      requiresPayment: json.requirePayment,
      paymentAmount: json.paymentAmount,
      requiresScreening: json.requireScreening,
      questions: json.questions,
    };
  };

  private createRentalApplicationUpdateRequestBody = (application: RentalApplication) => {
    const questionDictionary: { [key: string]: string } | undefined = application.questionsAndAnswers?.reduce((acc, current) => {
      acc[current.question] = current.answers.join('|');
      return acc;
    }, {} as { [key: string]: string });

    return JSON.stringify({
      firstName: application.firstName,
      lastName: application.lastName,
      email: application.email,
      phone: application.phone,
      ssn: application.ssn,
      tenantId: application.tenantId,
      dateOfBirth: application.dateOfBirth,
      applyingWith: application.applyingWith,
      interestedUnitIds: application.interestedUnits,
      desiredMoveInDate: application.desiredMoveInDate,
      maritalStatus: application.maritalStatus,
      driversLicense: application.driversLicense,
      incomeInformation: {
        annualIncome: application?.annualIncome,
      },
      pets: application.pets,
      residentialHistory: application.residentialHistory?.map((history) => ({
        ...history,
        landlordName: this.nullOutFieldIfEmpty(history.landlordName),
        moveOutDate: this.nullOutFieldIfEmpty(history.moveOutDate),
        landlordPhone: this.nullOutFieldIfEmpty(history.landlordPhone),
        reasonForLeaving: this.nullOutFieldIfEmpty(history.reasonForLeaving),
        address: {
          ...history.address,
          streetAddress2: this.nullOutFieldIfEmpty(history.address?.streetAddress2),
        },
      })),
      emergencyContact: application.emergencyContact,
      employment: application.employmentHistory?.map((history) => ({
        ...history,
        phone: this.nullOutFieldIfEmpty(history.phone),
        endDate: this.nullOutFieldIfEmpty(history.endDate),
      })),
      questions: questionDictionary,
      comments: application.comments,
      files: application.files,
    });
  };

  private nullOutFieldIfEmpty = (field?: string): string | undefined => {
    return field === undefined || field === '' ? undefined : field;
  };

  private createRentalApplicationFromJson = (json: any): RentalApplication => {
    return {
      credentials: {
        id: json.id,
        password: json.password,
      },
      firstName: json.firstName,
      lastName: json.lastName,
      email: json.email,
      phone: json.phone,
      ssn: json.ssn,
      tenantId: json.tenantId,
      dateOfBirth: json.dateOfBirth,
      applyingWith: json.applyingWith,
      interestedUnits: this.getUnitIdsFromJson(json),
      desiredMoveInDate: json.desiredMoveInDate,
      maritalStatus: json.maritalStatus,
      driversLicense: json.driversLicense,
      annualIncome: json.incomeInformation ? json.incomeInformation.annualIncome : undefined,
      pets: json.pets,
      residentialHistory: json.residentialHistory,
      emergencyContact: json.emergencyContact,
      employmentHistory: json.employment,
      comments: json.comments,
      questionsAndAnswers: this.getQuestionsFromJson(json),
      createdAt: json.createdAt,
      updatedAt: json.updatedAt,
      files: this.remapFiles(json.files),
      isDraft: json.draft,
      paymentStatus: json.paymentStatus,
      submittedAt: json.submittedAt,
      requiresPayment: !json.screeningPaid,
      screeningStatus: json.screeningStatus,
      newFiles: [],
    };
  };

  private getQuestionsFromJson = (json: any): QuestionAndAnswers[] => {
    if (!json.questions) {
      return [];
    }
    return Object.entries(json.questions).map(([key, value]) => {
      const question: string = key;
      const answers: string[] = [];
      answers.push(value as string);
      return { question, answers };
    });
  };

  private getUnitIdsFromJson = (json: any): string[] => {
    const ids = json.interests.map((interest: any) => interest.unit.id);
    return ids;
  };

  private remapFiles = (files: any): HydratedRentalApplicationFileDto[] => {
    return files.map((file: any) => {
      const fileUrl = file.signedUrl ? '/api' + file.signedUrl : '';
      const thumbUrl = file.signedThumbUrl ? '/api' + file.signedThumbUrl : fileUrl;
      return {
        fileId: file.fileId,
        fileName: file.fileName,
        fileExtension: '',
        fileUrl,
        thumbUrl,
        type: file.type,
        contentType: '',
        fileSize: file.fileSize,
      };
    });
  };

  public async uploadRentalApplicationFile(descriptor: FileDescriptor): Promise<FileUploadResult> {
    const formData = new FormData();
    formData.append('file', descriptor.file);
    const request: MagicRequest = {
      url: '/api/files',
      method: RequestMethod.POST,
      headers: new Headers(),
      body: formData,
    };
    const response = await this.sendRequest(request);
    const json = await response.json();
    return json.file;
  }

  async lockRentalApplication(credentials: RentalApplicationCredentials): Promise<void> {
    const request: MagicRequest = {
      url: `${RENTAL_APPLICATIONS_URL}/${credentials.id}/${credentials.password}/lock`,
      method: RequestMethod.POST,
      headers: this.createCommonHeaders(),
    };
    await this.sendRequest(request);
  }

  async getPaymentIntentForRentalApplication(credentials: RentalApplicationCredentials): Promise<StripeInfo> {
    const request: MagicRequest = {
      url: `${RENTAL_APPLICATIONS_URL}/${credentials.id}/${credentials.password}/payment`,
      method: RequestMethod.POST,
      headers: this.createCommonHeaders(),
    };
    const response: Response = await this.sendRequest(request);
    const json = await response.json();
    return {
      stripeAccountId: json.stripeAccountId,
      clientSecret: json.clientSecret,
    };
  }

  async fetchRentalApplicationPaymentStatus(credentials: RentalApplicationCredentials): Promise<boolean> {
    const request: MagicRequest = {
      url: `${RENTAL_APPLICATIONS_URL}/${credentials.id}/${credentials.password}/payment`,
      method: RequestMethod.GET,
      headers: this.createCommonHeaders(),
    };
    const response: Response = await this.sendRequest(request);
    const json = await response.json();
    return json.paid;
  }

  async startTransUnionScreening(credentials: RentalApplicationCredentials): Promise<TransUnionScreening> {
    const request: MagicRequest = {
      url: `${RENTAL_APPLICATIONS_URL}/${credentials.id}/${credentials.password}/screening`,
      method: RequestMethod.POST,
      headers: this.createCommonHeaders(),
    };
    try {
      const response: Response = await this.sendRequest(request);
      const json = await response.json();
      return {
        status: json.status,
        transunionScreeningId: json.transunionScreeningRequestId,
        transunionScreeningRequestRenterId: json.transunionScreeningRequestRenterId,
      };
    } catch (error: any) {
      if (error instanceof NotFoundError) {
        const errorJson = error.getJson<HttpErrorJson>();
        if (errorJson?.type === COMPANY_BUSINESS_INFO_NOT_FOUND_EXCEPTION) {
          throw new CannotScreeningError();
        }
      }
      throw error;
    }
  }

  async getTransUnionScreeningStatus(credentials: RentalApplicationCredentials): Promise<TransUnionScreening> {
    const request: MagicRequest = {
      url: `${RENTAL_APPLICATIONS_URL}/${credentials.id}/${credentials.password}/screening`,
      method: RequestMethod.GET,
      headers: this.createCommonHeaders(),
    };
    const response: Response = await this.sendRequest(request);
    const json = await response.json();
    return {
      status: json.status,
      transunionScreeningId: json.transunionScreeningRequestId,
      transunionScreeningRequestRenterId: json.transunionScreeningRequestRenterId,
    };
  }

  async getTransUnionScreeningQuestions(credentials: RentalApplicationCredentials): Promise<TransUnionScreening> {
    const request: MagicRequest = {
      url: `${RENTAL_APPLICATIONS_URL}/${credentials.id}/${credentials.password}/exam`,
      method: RequestMethod.POST,
      headers: this.createCommonHeaders(),
    };
    const response: Response = await this.sendRequest(request);
    const json = await response.json();
    return {
      status: json.status,
      transunionScreeningId: json.transunionScreeningRequestId,
      transunionScreeningRequestRenterId: json.transunionScreeningRequestRenterId,
      examId: json.examId,
      questions: json.questions,
    };
  }

  async sendTransUnionScreeningAnswers(
    credentials: RentalApplicationCredentials,
    answers: TransUnionAnswerSheet
  ): Promise<TransUnionScreeningStatus> {
    const request: MagicRequest = {
      url: `${RENTAL_APPLICATIONS_URL}/${credentials.id}/${credentials.password}/answers`,
      method: RequestMethod.POST,
      headers: this.createCommonHeaders(),
      body: JSON.stringify(answers),
    };
    const response: Response = await this.sendRequest(request);
    const json = await response.json();
    return json.screeningStatus;
  }
}

export const rentalApplicationGateway = new RentalApplicationGateway(companyIdAttachingNetworkManager);
